angular.module('cards', []).controller('CardsController', ['$timeout', '$scope', '$http', '$localization', ($timeout, $scope, $http, $localization) => {
  var AttributeValues = {
    MAX_IMPORTANCE: 5,
    MIN_IMPORTANCE: 1,
    MAX_KNOWNLEDGE: 5,
    MIN_KNOWNLEDGE: 1,
    DEFAULT_KNOWNLEDGE: 3,
    DEFAULT_IMPORTANCE: 3
  };
  var CardContentType = {
    TEXT: 'TEXT',
    HTML: 'HTML',
    IMAGE: 'IMAGE',
    AUDIO: 'AUDIO',
    VIDEO: 'VIDEO'
  };
  $scope.locale = $localization;
  $scope.autoPlayTimer = null;
  $scope.currentCardContent = {};
  $scope.userCardAttributes = [];
  $scope.loadedCards = [];
  $scope.selectedGroups = [];
  $scope.groupsLoading = true;
  $scope.showMenu = true;
  $scope.showCards = false;
  $scope.currentCardSide = true;
  $scope.isAutoplayEnabled = false;
  $scope.isPlaying = false;
  $scope.playNextSfx = () => {
    var i = Math.floor(Math.random() * 6) + 1;
    var sfx = new Audio(`/sound/page-flip-${i}.mp3`);
    sfx.volume = 0.1;
    sfx.play();
  }
  $scope.initialize = () => {
    $http({
      url: "/api/user/cards/attributes/",
      method: "GET"
    }).then((response) => {
      $scope.userCardAttributes = response.data;
      $http({
        url: "/api/cards/groups/allowed/",
        method: "GET"
      }).then((response) => {
        $scope.userAllowedGroups = response.data;
        $scope.selectedGroups = [];
        $timeout(() => {
          $scope.showCards = false;
          $scope.showMenu = true;
          $scope.groupsLoading = false;
          $scope.currentCardSide = true;
        }, 500);
      });
    });
  }
  $scope.onGroupClicked = (group) => {
    if (!group.selected) {
      $scope.selectedGroups.push(group);
      group.selected = true;
    } else {
      $scope.selectedGroups = $scope.selectedGroups.filter((obj) => {
        return obj.$$hashKey != group.$$hashKey;
      });
      group.selected = false;
    }
  }
  $scope.sequencedCardsComparator = (a, b) => {
    var aAttributes = $scope.userCardAttributes[a.id];
    var bAttributes = $scope.userCardAttributes[b.id];
    if (aAttributes == null) {
      aAttributes = $scope.getDefaultAttributes();
    }
    if (bAttributes == null) {
      bAttributes = $scope.getDefaultAttributes();
    }
    if (!aAttributes.isSequenced || !bAttributes.isSequenced) {
      return 0;
    }
    if (a.id > b.id) {
      return 1;
    }
    if (a.id < b.id) {
      return -1;
    }
    return 0;
  }
  $scope.cardSortComparator = (a, b) => {
    var aAttributes = $scope.userCardAttributes[a.id];
    var bAttributes = $scope.userCardAttributes[b.id];
    if (aAttributes == null) {
      aAttributes = $scope.getDefaultAttributes();
    }
    if (bAttributes == null) {
      bAttributes = $scope.getDefaultAttributes();
    }
    if (aAttributes.isSequenced || bAttributes.isSequenced) {
      return 0;
    }
    var aWeight = 5 / aAttributes.knownledge * aAttributes.importance;
    var bWeight = 5 / bAttributes.knownledge * bAttributes.importance;
    if (aWeight > bWeight) {
      return -1;
    }
    if (aWeight < bWeight) {
      return 1;
    }
    return 0;
  }
  $scope.knownCardsFilter = (value, index, ar) => {
    var attributes = $scope.userCardAttributes[value.id];
    if (attributes == null) {
      attributes = $scope.getDefaultAttributes();
    }
    if (attributes.isSequenced) {
      return true;
    }
    if (attributes.knownledge == AttributeValues.MAX_KNOWNLEDGE) {
      return false;
    }
    if (attributes.importance == AttributeValues.MIN_IMPORTANCE) {
      return false;
    }
    return true;
  }
  $scope.getDefaultAttributes = () => {
    return { 
      knownledge: AttributeValues.DEFAULT_KNOWNLEDGE, 
      importance: AttributeValues.DEFAULT_IMPORTANCE,
      isSequenced: false
    };
  }
  $scope.openSelectedPackages = () => {
    $scope.groupsLoading = true;
    $scope.loadedCards = [];
    $scope.selectedGroups.forEach((group) => {
      var isGroupSequenced = group.flags.includes('IS_SEQUENCED');
      group.cards.forEach((card) => {
        $scope.userCardAttributes[card.id] = $scope.userCardAttributes[card.id] == null ? $scope.getDefaultAttributes() : $scope.userCardAttributes[card.id];
        $scope.userCardAttributes[card.id].isSequenced = isGroupSequenced;
      });
      $scope.loadedCards = $scope.loadedCards.concat(group.cards.sort($scope.sequencedCardsComparator));
    });
    $scope.loadedCards = $scope.loadedCards.sort($scope.cardSortComparator);
    $scope.loadedCards = $scope.loadedCards.filter($scope.knownCardsFilter);
    $scope.loadedCards = $scope.loadedCards.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
    $scope.groupsLoading = false;
    if ($scope.loadedCards.length == 0) {
      $scope.menuAlert.open('WARNING', $localization.get('cards.selection.has.no.cards'), 12000);
    } else {
      $scope.showMenu = false;
      $scope.showCards = true;
      $scope.currentCardIdx = 0;
      $scope.currentCard = $scope.loadedCards[0];
      $scope.currentCardContent.type = null;
      $timeout(() => {
        $scope.currentCardContent = angular.copy($scope.loadedCards[0].content.FRONT);
      });
      $scope.currentCardAttributes = $scope.userCardAttributes[$scope.currentCard.id] == null ? $scope.getDefaultAttributes() : $scope.userCardAttributes[$scope.currentCard.id];
    }
  }
  $scope.openTopCards = () => {
    $scope.groupsLoading = true;
    $scope.loadedCards = [];
    $scope.userAllowedGroups.forEach((group) => {
      $scope.loadedCards = $scope.loadedCards.concat(group.cards);
      var isGroupSequenced = group.flags.includes('IS_SEQUENCED');
      group.cards.forEach((card) => {
        $scope.userCardAttributes[card.id] = $scope.userCardAttributes[card.id] == null ? $scope.getDefaultAttributes() : $scope.userCardAttributes[card.id];
        $scope.userCardAttributes[card.id].isSequenced = isGroupSequenced;
      });
      $scope.loadedCards = $scope.loadedCards.concat(group.cards.sort($scope.sequencedCardsComparator));
    });
    $scope.loadedCards = $scope.loadedCards.sort($scope.cardSortComparator);
    $scope.loadedCards = $scope.loadedCards.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
    $scope.loadedCards = $scope.loadedCards.filter($scope.knownCardsFilter);
    $scope.groupsLoading = false;
    if ($scope.loadedCards.length == 0) {
      $scope.menuAlert.open('WARNING', $localization.get('cards.top.has.no.cards'), 12000);
    } else {
      $scope.loadedCards = $scope.loadedCards.slice(0, 33);
      $scope.showMenu = false;
      $scope.showCards = true;
      $scope.currentCardIdx = 0;
      $scope.currentCard = $scope.loadedCards[0];
      $scope.currentCardContent.type = null;
      $timeout(() => {
        $scope.currentCardContent = angular.copy($scope.loadedCards[0].content.FRONT);
      });
      $scope.currentCardAttributes = $scope.userCardAttributes[$scope.currentCard.id] == null ? $scope.getDefaultAttributes() : $scope.userCardAttributes[$scope.currentCard.id];
    }
  }
  $scope.selectAllPackages = () => {
    $scope.userAllowedGroups.forEach((group) => {
      $scope.selectedGroups.push(group);
      group.selected = true;
    });
  }
  $scope.playAudio = () => {
    var audio = document.getElementById("audio-content");
    audio.addEventListener("play", $scope.onMediaPlay, true);
    audio.addEventListener("ended", $scope.onMediaStop, true);
    audio.src = `/api/cards/content/stream/${$scope.currentCard.id}/${$scope.currentCardSide}/${$scope.currentCardContent.file.id}/`;
    audio.play();
  }
  $scope.playVideo = () => {
    var video = document.getElementById("video-content");
    video.addEventListener("play", $scope.onMediaPlay, true);
    video.addEventListener("ended", $scope.onMediaStop, true);
    var canvas = document.getElementById("video-canvas");
    var cardContent = document.getElementById("card-content");
    var context = canvas.getContext('2d');
    canvas.width = cardContent.clientWidth;
    canvas.height = cardContent.clientHeight;
    var draw = (v, c, w, h) => {
      if(v.paused || v.ended) { 
        return false;
      }
      c.drawImage(v, 0, 0, w, h);
      setTimeout(draw, 40, v, c, w, h);
    }
    video.addEventListener('play', function() {
        draw(this, context, canvas.width, canvas.height);
    }, false);
    video.src = `/api/cards/content/stream/${$scope.currentCard.id}/${$scope.currentCardSide}/${$scope.currentCardContent.file.id}/`;
    video.play();
  }
  $scope.changeImportance = (level) => {
    if ($scope.userCardAttributes[$scope.currentCard.id].importance == level) {
      return;
    }
    $scope.userCardAttributes[$scope.currentCard.id].importance = level;
    $scope.currentCardAttributes.importance = level;
    $http({
      url: "/api/user/cards/importance/",
      method: "POST",
      params: {
        cardId: $scope.currentCard.id,
        importance: level
      }
    });
    var sfx = new Audio(`/sound/tiny-click.mp3`);
    sfx.volume = 0.1;
    sfx.play();
  }
  $scope.changeKnownledge = (level) => {
    if ($scope.userCardAttributes[$scope.currentCard.id].knownledge == level) {
      return;
    }
    $scope.userCardAttributes[$scope.currentCard.id].knownledge = level;
    $scope.currentCardAttributes.knownledge = level;
    $http({
      url: "/api/user/cards/knowngledge/",
      method: "POST",
      params: {
        cardId: $scope.currentCard.id,
        knownledge: level
      }
    });
    var sfx = new Audio(`/sound/tiny-click.mp3`);
    sfx.volume = 0.1;
    sfx.play();
  }
  $scope.previousCard = () => {
    if ($scope.currentCardIdx != 0) {
      $scope.currentCardSide = true;
      $scope.currentCardIdx--;
      $scope.currentCard = $scope.loadedCards[$scope.currentCardIdx];
      $scope.currentCardContent.type = null;
      $timeout(() => {
        $scope.currentCardContent = angular.copy($scope.loadedCards[$scope.currentCardIdx].content.FRONT);
      });
      $scope.currentCardAttributes = $scope.userCardAttributes[$scope.currentCard.id] == null ? $scope.getDefaultAttributes() : $scope.userCardAttributes[$scope.currentCard.id];
      $scope.playNextSfx();
      
      if ($scope.currentCard.content.FRONT.type != CardContentType.VIDEO && $scope.currentCard.content.FRONT.type != CardContentType.AUDIO) {
        $scope.onMediaStop();
      }
    }
  }
  $scope.nextCard = () => {
    if ($scope.currentCardIdx != ($scope.loadedCards.length - 1)) {
      $scope.currentCardSide = true;
      $scope.currentCardIdx++;
      $scope.currentCard = $scope.loadedCards[$scope.currentCardIdx];
      $scope.currentCardContent.type = null;
      $timeout(() => {
        $scope.currentCardContent = angular.copy($scope.loadedCards[$scope.currentCardIdx].content.FRONT);
      });
      $scope.currentCardAttributes = $scope.userCardAttributes[$scope.currentCard.id] == null ? $scope.getDefaultAttributes() : $scope.userCardAttributes[$scope.currentCard.id];
      $scope.playNextSfx();
      
      if ($scope.currentCard.content.FRONT.type != CardContentType.VIDEO && $scope.currentCard.content.FRONT.type != CardContentType.AUDIO) {
    	$scope.onMediaStop();
      }
    } else {
      $scope.currentCardIdx = -1;
      $scope.nextCard();
    }
  }
  $scope.flipCard = () => {
	$scope.currentCardContent.type = null;
	if ($scope.currentCardSide) {
      $timeout(() => {
	    $scope.currentCardContent = angular.copy($scope.loadedCards[$scope.currentCardIdx].content.BACK);
	    $scope.currentCardSide = false;
		if ($scope.currentCard.content.FRONT.type != CardContentType.VIDEO && $scope.currentCard.content.FRONT.type != CardContentType.AUDIO) {
		  $scope.onMediaStop();
	    }
      });
	  var sfx = new Audio(`/sound/click-on.mp3`);
	  sfx.volume = 0.1;
	  sfx.play();
	} else {
	  $timeout(() => {	
	    $scope.currentCardContent = angular.copy($scope.loadedCards[$scope.currentCardIdx].content.FRONT);
	    $scope.currentCardSide = true;
		if ($scope.currentCard.content.FRONT.type != CardContentType.VIDEO && $scope.currentCard.content.FRONT.type != CardContentType.AUDIO) {
		  $scope.onMediaStop();
		}
	  });
	  var sfx = new Audio(`/sound/click-off.mp3`);
	  sfx.volume = 0.3;
	  sfx.play();
	}
  }
  $scope.onMediaPlay = () => {
	$timeout.cancel($scope.autoPlayTimer);
	$scope.isPlaying = true;
  }
  $scope.onMediaStop = () => {
	$scope.isPlaying = false;
	if (!$scope.isAutoplayEnabled) {
      return;
	}
	$scope.autoPlayTimer = $timeout(() => {
      if ($scope.currentCardSide) {
    	$scope.flipCard();
      } else {
    	$scope.nextCard();
      }
    }, $scope.currentCardContent.flipTime == null ? 3000 : $scope.currentCardContent.flipTime * 1000);
  }
  $scope.returnToMenu = () => {
    $scope.isAutoplayEnabled = false;
	$timeout.cancel($scope.autoPlayTimer);
    var video = document.getElementById("video-content");
    var audio = document.getElementById("audio-content");
    if (video) {
      video.pause();
    }
    if (audio) {
      audio.pause();
    }
    $scope.groupsLoading = true;
    $scope.initialize();
  }
  $scope.toggleAutoPlay = () => {
	$scope.isAutoplayEnabled = !$scope.isAutoplayEnabled;
	if (!$scope.isPlaying) {
      $timeout.cancel($scope.autoPlayTimer);
      $scope.onMediaStop();
	}
  }
  $scope.initialize();
  $localization.ready().then(() => {});
}]);